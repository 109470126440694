<template>
    <div class=" ">
  <div class="row">
    <div class="col-9  col-sm-9 col-lg-10  fs-4  page-heading-parter ">
      <p class="mypracticeMobile pt-3" style="margin-left: 20px;"></p>
    </div>
    <div class="col-2 col-sm-3 col-lg-2">
        <div class="my-3 text-center">
    <button  class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="GoSettingBack()" >Back</button>
  </div>
    </div>
  </div>
  <div class="hr-border" ></div>
  </div>
  
  
  
  <div class="px-4 mt-4 row ">
  <div class=" col-lg-1 col-3 fs-5 ">
    <label class="inventory_pointer"  @click="enableAbout()" :class="{ active: isAboutActive }">About</label>
  </div>
  <div class="fs-5  col-lg-1 col-3">
    <label class="inventory_pointer" @click="enableOrders()" :class="{ active: isOrdersActive }">Orders</label>
  </div>
  <div class="fs-5  col-lg-1 col-3">
    <label class="inventory_pointer" @click="enableReport()" :class="{ active: isReportActive }">Report</label>
  </div>
  
  </div>
  
  
  <div class="" v-if="openAbout">
  </div>
  <div class="mt-3 row mt-3 " v-if="openOrders">
  <div class="col-lg-6 col-10 orders-border">
  <div class="row">
    <div class="col-lg-4 fs-5 d-flex">
      <div>
        <input type="radio" v-model="selectedSearchType" value="orderNumber" />
      </div>
      <div>
        <label class="ps-2">Order number</label>
      </div>
    </div> 
    <div class="col-lg-6 fs-5 d-flex">
      <div>
        <input type="radio" v-model="selectedSearchType" value="phoneNumber" />
      </div>
      <div>
        <label class="ps-2">Patient phone number</label>
      </div>
    </div>
  </div>

  <div class="row mt-3" v-if="selectedSearchType">
    <div class="col-lg-5 col-8" >
      <input class="form-control" type="text" id="mobile" maxlength="10" name="mobile"  v-model="searchValue" @input="validateSearchValue" placeholder="Enter search value" />
    </div>
    <div class="col-lg-3 col-4">
      <button
        class="btn bg-color text-white"
        @click="searchOrders"
      >
        Search
      </button>
    </div>
  </div>

  <div v-if="(hideOrderList && (this.selectedSearchType === 'phoneNumber'))">
    <hr>
        <p v-if="this.patientOrdersList.length">Order number</p>
        <p v-if="noItems">There are no items</p>

        <div class="row mt-2" v-for="(value, index) in patientOrdersList" :key="index">

          <router-link  :to="{path: `/doctor/manage/doctor-delivery-orders/${value.id}`, query: { role: role }}">
            <div class="SettingNameID">{{ 10000 + value.id }}</div>
          </router-link>
          
        </div>


      </div>
  </div>
</div>


  </template>
  <script>
  import axios from "axios";
  export default {
  props: ["docData"],
  data() {
  return {
    searchOrderNumber:'',
    selectedSearchType: null, 
    searchValue: '', 
    AcceptedOrders: [],
    noItems:false,
    patientOrdersList:[],
    clinicData:[],
    hideOrderList:false,
    noModel:true,
    isAboutActive: true,
    isOrdersActive: false,
    isReportActive: false,
    openAbout:true,
    openOrders:false,
    newModel:false,
    model_no:'',
    isActive:false,
    doctorData: {},
    mobileValidation:/^[6-9]\d{9}$/,
  };
  },
  
  
  methods: {

    async searchOrders() {


  if (!this.searchValue) {
    this.$swal("Please enter a search value!");
    return;
  }

  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;

  try {
    if (this.selectedSearchType === "orderNumber") {
      // Handle search by order number
      if (this.searchValue < 10001) {
        this.$swal("Please enter a valid order number starting from 10001!");
        return;
      }

      const formattedOrderNumber = Number(this.searchValue.toString().slice(-2));
      const response = await axios.get(`${BASE_API_URL}/footwear-order`);

      this.AcceptedOrders = response.data.filter((order) => {
        return (
          Number(order.id) === Number(formattedOrderNumber) &&
          Number(order.doctorId) === Number(this.DoctorId)
        );
      });


      if (this.AcceptedOrders.length > 0) {

        if(this.AcceptedOrders[0].order_status==="Created"){
          return this.$swal(`The patient did not confirm order  100${this.AcceptedOrders[0].id}`)
        }
        const query = this.$route.query;
        window.scrollTo(0, 0);
        this.$router.push({
          path: `/doctor/manage/doctor-delivery-orders/${this.AcceptedOrders[0].id}`,
          query,
        });
      } else {
        this.$swal("Not found", "Order not found!", "error");
      }
    } else if (this.selectedSearchType === "phoneNumber") {
      // Handle search by phone number
      const patientMobileNumber = Number(this.searchValue);

      const searchPatientNumber = await axios.get(`${BASE_API_URL}/public-user`);

      const searchedPatientData = searchPatientNumber.data.filter((order) => {
        return Number(order.mobile) === patientMobileNumber;
      });


      if (searchedPatientData.length > 0) {
        const doctorId = Number(searchedPatientData[0].id);

        const response = await axios.get(`${BASE_API_URL}/footwear-order`);
        this.patientOrdersList = response.data.filter((order) => {
          return Number(order.patient_name) === doctorId;
        });

        if (this.patientOrdersList.length > 0) {
          this.hideOrderList =true
        }
        if (this.patientOrdersList.length === 0) {
          this.noItems =true
          this.$swal("Not found", "No orders found for this phone number!", "error");
        }
      } else {
        this.$swal("Not found", "No patient found with this phone number!", "error");
      }
    }
  } catch (error) {
    this.$swal("Error", "Failed to fetch orders!", "error");
    console.error("Error fetching orders:", error);
  }
}
,
  enableAbout(){
    
    
    this.isAboutActive = !this.isAboutActive;
    this.openAbout=true
    this.isReportActive =false
    this.openOrders =false
    this.isOrdersActive = false;
  },
  enableReport(){
    this.isReportActive = !this.isReportActive;
    this.openOrders =false
    this.isOrdersActive = false;

  },
  enableOrders(){
  
   
    this.isAboutActive = false
    this.isOrdersActive = !this.isOrdersActive;
    this.isReportActive =false
    this.newModel = false;
    this.openAbout=false
    this.openOrders =true
  
  },
    enableClinic(){
       this.editbutton = false; 
       this.clearDatas();
    },
  
  GoSettingBack() {
    window.scrollTo(0, 0);
    this.$router.push(`/doctor/clinic/manage`);
  },
  


  validateSearchValue() {

    this.hideOrderList=false
  
      if (this.selectedSearchType === "phoneNumber") {
        this.searchValue = this.searchValue.replace(/\D/g, "").slice(0, 10);
      }
    
   },
  
  async getDoctorsAndClinic() {
  try {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
    
    // Fetch doctor data
    const doctorResponse = await axios.get(`${BASE_API_URL}/hcps/${this.DoctorId}/one`);
    this.doctorData = doctorResponse.data;
console.log(this.doctorData,"doctorData")
    if (this.doctorData) {
      // Fetch clinic data
      const clinicResponse = await axios.get(`${BASE_API_URL}/clinic-consultation`);
      this.clinicData = clinicResponse.data.filter((data) => {
        return (
          data?.id == this.doctorData?.clinic_hcp_id ||
          data?.id == this.doctorData?.clinic_admin_id||
          data?.hcps_id?.id == this.doctorData?.id
        );
      });
console.log(this.clinicData,"clinicData")

    }
  } catch (error) {
    console.error("An error occurred while fetching data:", error);
  }
},


 

  async getDoctors() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    await axios
      .get(`${BASE_API_URL}/hcps/${this.DoctorId}/one`)
      .then((response) => {
        this.doctorData = response.data
        this.coPincode = this.doctorData.pincode
      })
  },
  },
  created: async function () {
    let DoctorUserId = localStorage.getItem("id");
          this.DoctorId = JSON.parse(DoctorUserId);
           if(this.DoctorId){
  await this.getDoctors();
  await this.getDoctorsAndClinic();
     } 
  },
  };
  </script>
  <style>
  
  
  

  

  .clinicSubmitBtn{
    display: flex;
    justify-content:center;
  }
  .consultation-select{
  /* width: 300px; */
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-bottom: 5px;
  -webkit-appearance: auto;
  appearance:auto;
  }
  .clinicSubmitBtn{
    display: flex;
    justify-content:center;
  }

  legend.subHeadingText {
  padding: 4px 10px;
  margin-bottom: 0px;
  display: block;
  float: none;
  width: auto;
  font-size: 20px;
  }
  .allfieldcontent{
  color: red;
  margin-top: 15px
  }
  .star {
  color: red;
  font-size: 20px;
  }
  .hideOpacity{
  opacity: 0;
  }
  .yes-button,
  .no-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  }
  
  .yes-button {
  background-color: #38761D;
  color: #fff;
  }
  
  .no-button {
  background-color: #FF9900;
  color: #fff;
  }
  .popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  .page-heading-parter{
  color: #00979e;
  font-size: 28px;
  font-weight: bold;
  }
  
  .popup-content {
  text-align: center;
  background: #fff;
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .inventory_pointer{
  cursor: pointer;
  
  }
  .inventory_pointer.active {
  border-bottom: 2px solid #f6802a;
  }
  .clinicCreateBtn{
  color: #00979e !important;
  cursor: pointer;
  }
  
  button#btn_clinicconsult_add {
  border-color: transparent;
  }
  .SettingNameID {
  color: #00979e !important;
  font-weight: 600;
}
  .disableCreateBtn{
  color:  #dddddd !important;
  pointer-events: none;
  cursor: auto; 
  }
  .clinicTextAlign input{
  text-align: left !important;;
  }
  
  /* .receiveConsultation {
  color: #34989f;
  }
  
  .checkbox-inline {
  color: #34989f;
  } */
  .checkbox-inline.heading {
  font-size: 20px;
  margin-bottom: 0px;
  }
  
  .my-3.subHeading {
  /* color: #34989f; */
  font-size: 18px;
  }
  .orders-border{
    border-radius: 15px;
    border: 2px solid black;
    padding: 40px 30px;
    margin-left: 20px;
    
  }
  .ms-5.text-color {
  /* color: #34989f; */
  font-size: 18px;
  }
  @media screen and (max-width:991px) {
  .mobileStyleDoctor {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 6rem;
  }
  .order-number{
    width: 100%;
  }
  .orders-border{
    margin-left: 35px;

  }
  .mypracticeMobile {
  margin-left: 20px;
  /* margin-bottom: 10px; */
  color: #000;
  font-weight: 550;
  font-size: 21px;
  }
  }
  </style>
  